<template>
  <loading-component v-if="isLoading" />
  <div class="columns is-multiline" v-else>
    <modal-image v-if="imageModal" :close="closeModal" :image="image" />
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard is-flex">
      <div>
        {{ detail.productName }} ({{ detail.name }}) -
        <span class="tag" :class="[
          detail.statusName == 'Pendiente' ? 'is-info' : '',
          detail.statusName == 'Preaprobado' ? 'is-warning' : '',
          detail.statusName == 'Activo' ? 'is-success' : '',
        ]">{{ detail.statusName }}</span>
      </div>
    </div>

    <div class="column is-12 separator"></div>
    <div class="column is-12">
      <p class="title is-4" style="display: inline-block;"><strong>Calificacion del dispositivo: </strong></p>
      {{ ' ' }}
      <span class="tag is-medium" :class="{
        'has-background-color-a': this.data?.degree === 'A',
        'has-background-color-ab': this.data?.degree === 'AB',
        'has-background-color-c': this.data?.degree === 'C'
      }" :data-tooltip="tooltip"><strong style="color: white;">{{ this.data?.degree }}</strong></span>
      {{ ' ' }}
      <!-- Icono de edición -->
      <span class="icon is-clickable" style="vertical-align: sub;" @click="isEditing = !isEditing">
        <i class="material-icons click">edit</i>
      </span>

      <!-- Menú desplegable para seleccionar el grado -->
      <div v-if="isEditing" class="select">
        <select v-model="data.degree" @change="handleUpdateDegree">
          <option value="A">A</option>
          <option value="AB">AB</option>
          <option value="C">C</option>
        </select>
      </div>
      <div class="columns">
        <div class="column is-6">
          <strong>Diagnóstico del dispositivo</strong>
          <p><strong>Marca:</strong> {{ data?.diagnosticBrand ?? 'N/A' }}</p>
          <p><strong>Modelo:</strong> {{ data?.diagnosticModel ?? 'N/A' }}</p>
          <p><strong>Almacenamiento:</strong> {{ data?.diagnosticStorage ?? 'N/A' }}</p>
          <p><strong>IMEI:</strong> {{ data?.imei }}</p>
        </div>
        <div class="column is-6">
          <strong>Precios sugeridos al usuario</strong>
          <div v-if="data?.HasPricing">
              <div v-for="(price, grade) in data?.pricing" :key="grade" >
                <p><strong>      <span class="tag is-medium" style="color: white;margin-bottom: 5px" :class="{
        'has-background-color-a': grade === 'A',
        'has-background-color-ab': grade === 'AB',
        'has-background-color-b': grade === 'B',
        'has-background-color-c': grade === 'C'
      }">{{ grade }}</span> - </strong> <span style="font-size: 18px;font-weight: 600;">${{ price }}</span></p>
              </div>
          </div>
          <p v-else><strong>No hay precio recomendado disponible</strong></p>
        </div>
      </div>
      <div class="column is-12"></div>
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Prueba</th>
            <th>Original</th>
            <th>Input</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in computedData" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.original }}</td>
            <td>
              <template v-if="isUrl(item.input)">
                <a :href="item.input" class="button is-link" download rel="noopener noreferrer">
                  Descargar
                </a>
              </template>
              <template v-else>
                {{ item.input }}
              </template>
            </td>
            <td>
              <span
                v-if="item.name === 'Porcentaje de bateria'"
                class="tag is-medium"
                :class="{
                  'is-success has-text-light': parseInt(item.status) > 93,
                  'is-warning': parseInt(item.status) <= 93 && item.status !== 'Sin información',
                  'is-light': item.status === 'Sin información'
                }"
              >
                {{ item.status }}
              </span>
              <span
                v-else
                class="tag is-medium"
                :class="{ 'is-success': item.status === 'Aprobado', 'is-danger': item.status === 'Fallido' }"
              >
                {{ item.status }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  data () {
    return {
      imageModal: false,
      image: null,
      data: {},
      isLoading: false,
      isEditing: false,
      tooltip: `Grado A: Pantalla, biometría soportada y registrada, IMEI presente, batería > 93%.${'\n'}Grado AB: (Pantalla + biometría + IMEI) con batería ≤ 93%, o (sin pantalla + biometría + IMEI) con batería > 93%.${'\n'}Grado C: No cumple con las condiciones anteriores.`
    }
  },
  computed: {
    computedData () {
      return [
        { name: 'Pantalla', original: '-', input: '-', status: this.data?.screen ? 'Aprobado' : 'Fallido' },
        { name: 'Porcentaje de bateria', original: '-', input: '-', status: this.data?.battery === null ? 'Sin información' : `${this.data?.battery}%` },
        { name: 'Celular liberado', original: '-', input: '-', status: this.data?.unlockedPhone === null ? 'Sin información' : (this.data?.unlockedPhone ? 'Aprobado' : 'Fallido') },
        { name: 'Puerto de carga funcional', original: '-', input: '-', status: this.data?.chargingPort === null ? 'Sin información' : (this.data?.chargingPort ? 'Aprobado' : 'Fallido') },
        { name: 'Sensores biometricos', original: '-', input: '-', status: this.data?.biometricIsSupported ? this.data?.biometricIsEnrolled ? 'Aprobado' : 'Fallido' : 'No compatible' },
        {
          name: 'Altavoces',
          original: this.data?.speakersOriginalPhrase,
          input: this.data?.speakersInputPhrase,
          status: (
            this.data?.speakersOriginalPhrase &&
            this.data?.speakersInputPhrase &&
            (
              this.data?.speakersOriginalPhrase.toLowerCase().includes(this.data?.speakersInputPhrase.toLowerCase()) ||
              this.data?.speakersInputPhrase.toLowerCase().includes(this.data?.speakersOriginalPhrase.toLowerCase())
            )
          ) ? 'Aprobado' : 'Fallido'
        },
        { name: 'Micrófono', original: this.data?.speakersMicOriginalPhrase, input: this.data?.speakersMicInputPhrase, status: this.data?.speakersMicInputPhrase ? 'Aprobado' : 'Fallido' }
      ]
    }
  },
  methods: {
    ...mapActions(['getDiagnostic', 'updateDegree']),
    showImageModal (image) {
      this.image = image
      this.imageModal = true
    },
    closeModal () {
      this.imageModal = false
    },
    isUrl (input) {
      const httpsPattern = /^https:\/\//
      return httpsPattern.test(input)
    },
    async handleUpdateDegree () {
      this.isEditing = false

      await this.updateDegree({ productId: this.detail.id, degree: this.data.degree })
    }
  },
  async beforeMount () {
    try {
      this.isLoading = true
      let response = await this.getDiagnostic({ productId: this.detail.id })
      this.data = response
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.table {
  border: 1px solid lightgray;
  border-radius: 15px;
  border-collapse: separate;
}
.table tr {
  border-bottom: 1px solid lightgray;
}
.table th {
  background-color: transparent !important;
  color: rgb(110, 110, 119) !important;
  padding: 0.5em 0.75em;
}
.has-background-color-a {
  background-color: #4CAF50;
}
.has-background-color-ab {
  background-color: #66BB6A;
}
.has-background-color-b {
  background-color: #fcd665;
}
.has-background-color-c {
  background-color: #FFCA28;
}

</style>
