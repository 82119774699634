var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading)?_c('loading-component'):_c('div',{staticClass:"columns is-multiline"},[(_vm.imageModal)?_c('modal-image',{attrs:{"close":_vm.closeModal,"image":_vm.image}}):_vm._e(),_c('div',{staticClass:"column is-12 is-paddingless is-marginless has-text-left titleCard is-flex"},[_c('div',[_vm._v(" "+_vm._s(_vm.detail.productName)+" ("+_vm._s(_vm.detail.name)+") - "),_c('span',{staticClass:"tag",class:[
        _vm.detail.statusName == 'Pendiente' ? 'is-info' : '',
        _vm.detail.statusName == 'Preaprobado' ? 'is-warning' : '',
        _vm.detail.statusName == 'Activo' ? 'is-success' : '',
      ]},[_vm._v(_vm._s(_vm.detail.statusName))])])]),_c('div',{staticClass:"column is-12 separator"}),_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"title is-4",staticStyle:{"display":"inline-block"}},[_c('strong',[_vm._v("Calificacion del dispositivo: ")])]),_vm._v(" "+_vm._s(' ')+" "),_c('span',{staticClass:"tag is-medium",class:{
      'has-background-color-a': this.data?.degree === 'A',
      'has-background-color-ab': this.data?.degree === 'AB',
      'has-background-color-c': this.data?.degree === 'C'
    },attrs:{"data-tooltip":_vm.tooltip}},[_c('strong',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(this.data?.degree))])]),_vm._v(" "+_vm._s(' ')+" "),_c('span',{staticClass:"icon is-clickable",staticStyle:{"vertical-align":"sub"},on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},[_c('i',{staticClass:"material-icons click"},[_vm._v("edit")])]),(_vm.isEditing)?_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.degree),expression:"data.degree"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "degree", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.handleUpdateDegree]}},[_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"AB"}},[_vm._v("AB")]),_c('option',{attrs:{"value":"C"}},[_vm._v("C")])])]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('strong',[_vm._v("Diagnóstico del dispositivo")]),_c('p',[_c('strong',[_vm._v("Marca:")]),_vm._v(" "+_vm._s(_vm.data?.diagnosticBrand ?? 'N/A'))]),_c('p',[_c('strong',[_vm._v("Modelo:")]),_vm._v(" "+_vm._s(_vm.data?.diagnosticModel ?? 'N/A'))]),_c('p',[_c('strong',[_vm._v("Almacenamiento:")]),_vm._v(" "+_vm._s(_vm.data?.diagnosticStorage ?? 'N/A'))]),_c('p',[_c('strong',[_vm._v("IMEI:")]),_vm._v(" "+_vm._s(_vm.data?.imei))])]),_c('div',{staticClass:"column is-6"},[_c('strong',[_vm._v("Precios sugeridos al usuario")]),(_vm.data?.HasPricing)?_c('div',_vm._l((_vm.data?.pricing),function(price,grade){return _c('div',{key:grade},[_c('p',[_c('strong',[_c('span',{staticClass:"tag is-medium",class:{
      'has-background-color-a': grade === 'A',
      'has-background-color-ab': grade === 'AB',
      'has-background-color-b': grade === 'B',
      'has-background-color-c': grade === 'C'
    },staticStyle:{"color":"white","margin-bottom":"5px"}},[_vm._v(_vm._s(grade))]),_vm._v(" - ")]),_vm._v(" "),_c('span',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v("$"+_vm._s(price))])])])}),0):_c('p',[_c('strong',[_vm._v("No hay precio recomendado disponible")])])])]),_c('div',{staticClass:"column is-12"}),_c('table',{staticClass:"table is-fullwidth is-hoverable"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Prueba")]),_c('th',[_vm._v("Original")]),_c('th',[_vm._v("Input")]),_c('th',[_vm._v("Resultado")])])]),_c('tbody',_vm._l((_vm.computedData),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.original))]),_c('td',[(_vm.isUrl(item.input))?[_c('a',{staticClass:"button is-link",attrs:{"href":item.input,"download":"","rel":"noopener noreferrer"}},[_vm._v(" Descargar ")])]:[_vm._v(" "+_vm._s(item.input)+" ")]],2),_c('td',[(item.name === 'Porcentaje de bateria')?_c('span',{staticClass:"tag is-medium",class:{
                'is-success has-text-light': parseInt(item.status) > 93,
                'is-warning': parseInt(item.status) <= 93 && item.status !== 'Sin información',
                'is-light': item.status === 'Sin información'
              }},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('span',{staticClass:"tag is-medium",class:{ 'is-success': item.status === 'Aprobado', 'is-danger': item.status === 'Fallido' }},[_vm._v(" "+_vm._s(item.status)+" ")])])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }